import { Icon } from "@mdi/react";
import { MutableRefObject, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Add, ArrowBack, ErrorOutlineOutlined, MoreVert } from "@material-ui/icons";
import { mdiClock } from "@mdi/js";
import { Alert, Button, Dropdown, InputRef, Popover, Select, Tooltip } from "antd";
import _debounce from "lodash/debounce";
import {
  ICheckedForecast,
  setIsTypeWellSavable,
  setSelectedTypeWell
} from "store/features";
import { RootState } from "store/rootReducer";
import styled from "styled-components";
import { isPrimaryProduct, isRatio } from "utils/arps";

import { ArpSegment, ArpsDesignerKind, UserArpsItem } from "models/UserArpsModel";

import { useTypeWellTemplates } from "components/arps/hooks/useTypeWellTemplates";
import { ErrorBoundary } from "components/base";
import { CumulativeData } from "components/forecasting/Forecasting";
import { OnTime } from "components/forecasting/models/forecastWell";
import { useSelectedProjectPermissions } from "components/project/projects/hooks";
import { ArpInput, ButtonIconCentered } from "components/shared";
import { RESERVE_CATEGORIES } from "components/sync/util";
import { useIsUserForecaster } from "components/user/hooks";

import {
  getDefaultRateUnitsFromString,
  productStringToProductTypeEnum
} from "../../../../../utils";
import ChevronDown from "../../../../icons/ChevronDown";
import useUpdateEUR from "../../../hooks/useUpdateEUR";
import { ProductSegmentTemplate, SegmentTemplate } from "../../../models/SegmentTemplate";
import { arpsWasm } from "../../../utils/UpdateSegment";
import {
  convertDecline,
  getPrimarySegmentEndDate,
  getRampUpSegmentCumVolume,
  isRampUpSegment
} from "../../../utils/arpsUtils";
import { getDurationInYears } from "../../../utils/dates";
import { getDeclineTitleWithType } from "../../../utils/declineHelpers";
import { groupArpsSegmentsByProduct } from "../../../utils/groupArpsSegmentsByProduct";
import {
  extendArpsWrapper,
  getAvailableProducts,
  getData,
  getDefaultTypeWellTemplate,
  getOverallTemplateName,
  getUniqueRowAndDisplayHeadersSorted,
  updateArps,
  updateHeaders,
  updateProductTemplates,
  validateSegments
} from "../../../utils/typeWellUtils";
import { GetProductLabel } from "./GetProductLabel";
import OnTimeEditorModal from "./OnTimeEditorModal";
import { ProductMoreOptions } from "./ProductMoreOptions";
import { TypeWellProductItem, typeWellProductsMenuItems } from "./TypeWellProductOptions";

export interface TypeWellInputTableModel {
  arps?: ICheckedForecast;
  declineType: string;
  selectedTypeWellTitle: string;
  selectedTypeWellRescat: string;
  handleReload?: (item?: UserArpsItem) => void;
  handleReset?: () => void;
  handleSave?: (item?: UserArpsItem) => void;
  onChange: (item: ICheckedForecast) => void;
  kind: ArpsDesignerKind;
  onOnTimeChange?: (item: OnTime[]) => void;
  saveStatus?;
  cumulativeData?: CumulativeData | null;
}

export default function TypeWellInputTable({
  arps,
  declineType,
  selectedTypeWellTitle,
  selectedTypeWellRescat,
  handleReload,
  handleReset,
  handleSave,
  onChange,
  onOnTimeChange,
  kind,
  saveStatus,
  cumulativeData
}: TypeWellInputTableModel) {
  const dispatch = useDispatch();

  const { updateEUR } = useUpdateEUR();

  const typeWellSettings = useSelector(
    (state: RootState) => state.userSetting.typeWellSettings
  );

  const projectPermissions = useSelectedProjectPermissions();
  const isUserForecaster = useIsUserForecaster();

  const typeWellTemplates = useTypeWellTemplates();
  const inputRefs = useRef<MutableRefObject<InputRef>[]>([]);

  const [overallSegmentTemplate, setOverallSegmentTemplate] = useState<SegmentTemplate>();
  const [productSegmentTemplate, setProductSegmentTemplate] =
    useState<ProductSegmentTemplate>({});

  const uniqueProductFields = getUniqueRowAndDisplayHeadersSorted(productSegmentTemplate);

  const [mode, setMode] = useState<"Product" | "Segment">("Product");
  const [errors, setErrors] = useState({});
  const [warnings, setWarnings] = useState({});
  const [templateError, setTemplateError] = useState(undefined);
  const [onTime, setOnTime] = useState<{ open: boolean; product: string }>({
    open: false,
    product: ""
  });

  const [products, setProducts] = useState(
    getAvailableProducts(
      arps,
      typeWellProductsMenuItems(kind, isUserForecaster) as TypeWellProductItem[]
    )
  );

  const INITIAL_CALCULATED_FIELDS: { [product: string]: string[] } = {
    OIL: ["EUR"],
    GAS: ["EUR"],
    "SALES GAS": ["EUR"],
    WATER: ["EUR"],
    "COND.": ["EUR"],
    CONDENSATE: ["EUR"]
  };

  const [calculatedFields, setCalculatedFields] = useState<{
    [product: string]: string[];
  }>(INITIAL_CALCULATED_FIELDS);

  const resetCalculatedFields = () => {
    setCalculatedFields(INITIAL_CALCULATED_FIELDS);
  };

  const hasSource = !!arps?.source;

  const selectedTypeWell = useSelector((state: RootState) => state.arps.selectedTypeWell);

  useEffect(() => {
    setOverallSegmentTemplateAndUpdateHeader(
      getData({
        arps,
        arpsLib: arpsWasm,
        segmentTemplateName: overallSegmentTemplate?.name,
        kind,
        setTemplateError,
        typeWellTemplates,
        cumulativeData,
        declineType,
        typeWellSettings,
        updateEUR
      })
    );
    setMode("Product");
  }, [declineType, typeWellTemplates, cumulativeData, overallSegmentTemplate?.name]);

  useEffect(() => {
    if (!arpsWasm || !arps || !arps.arps) {
      return;
    }

    setProducts(
      getAvailableProducts(
        arps,
        typeWellProductsMenuItems(kind, isUserForecaster) as TypeWellProductItem[]
      )
    );
    setMode("Product");

    const updatedProductTemplates = updateProductTemplates({
      arps,
      productSegmentTemplate,
      typeWellTemplates,
      arpsWasm,
      kind,
      setTemplateError,
      cumulativeData,
      declineType,
      typeWellSettings,
      updateEUR
    });
    setProductSegmentTemplate(updatedProductTemplates);

    if (!arps.arps.length) {
      const defaultTypeWellTemplate = getDefaultTypeWellTemplate({
        kind,
        typeWellTemplates
      });
      setOverallSegmentTemplate(defaultTypeWellTemplate);
    }
  }, [arps, setProducts]);

  // On the first load of the page validateSegments() gets ran without segmentTemplate updating
  // Need this to fix warnings for two/three segment templates
  useEffect(() => {
    if (!arps || arps?.isFolder) {
      return;
    }
    if (arps?.arps && productSegmentTemplate) {
      validateSegmentsWrapper({ arps: arps.arps, productSegmentTemplate });
    }
  }, [arps, productSegmentTemplate]);

  const debounceUpdate = useMemo(
    () =>
      _debounce(
        (arps: ICheckedForecast, product: string, header: string, val, fn) =>
          fn(arps, product, header, val),
        600
      ),
    []
  );

  const setOverallSegmentTemplateAndUpdateHeader = (newTemplate: SegmentTemplate) => {
    if (!newTemplate) return;

    const updatedTemplate = updateHeaders(newTemplate, declineType);
    setOverallSegmentTemplate(updatedTemplate);
  };

  const validateSegmentsWrapper = ({
    arps,
    productSegmentTemplate
  }: {
    arps: ArpSegment[];
    productSegmentTemplate: ProductSegmentTemplate;
  }) => {
    const allErrors = {};
    const allWarnings = {};

    const firstProduct = Object.keys(productSegmentTemplate)[0];

    Object.keys(productSegmentTemplate).forEach((product) => {
      const segmentTemplate = productSegmentTemplate[product];

      const primarySegmentEndDate: Date = getPrimarySegmentEndDate(
        arps,
        kind,
        cumulativeData,
        segmentTemplate
      );

      const isPrimaryTypeWellSegment: boolean = firstProduct === product;

      const arpsFiltered = arps.filter(
        (segment: ArpSegment): boolean => segment?.product === product
      );

      const { errors, warnings } = validateSegments({
        arpsSegments: arpsFiltered,
        productSegmentTemplate,
        kind,
        cumulativeData,
        primarySegmentEndDate,
        isPrimaryTypeWellSegment
      });

      if (errors.length > 0) {
        allErrors[product] = errors;
      }
      if (warnings.length > 0) {
        allWarnings[product] = warnings;
      }
    });

    setErrors(allErrors);
    setWarnings(allWarnings);
  };

  const deleteProduct = (product: string) => {
    const removeProductFromList = (list) => {
      const idx = list.findIndex((item) => item.product == product);
      if (idx < 0) {
        return list;
      }
      const len = list.filter((item) => item.product === product).length;
      const newList = [...list];
      newList.splice(idx, len);
      return newList;
    };

    const arpsDecline = removeProductFromList(arps.arps);

    // If there is no primary prod in the arps, remove all constants
    const forecastConstants = arpsDecline.length
      ? removeProductFromList(arps.constants)
      : [];

    const userArps = Object.assign({}, arps, {
      arps: arpsDecline,
      constants: forecastConstants
    });

    const updatedProductSegmentTemplate = { ...productSegmentTemplate };
    delete updatedProductSegmentTemplate[product];

    setCalculatedFields({ ...calculatedFields, [product]: ["EUR"] });
    setProductSegmentTemplate(updatedProductSegmentTemplate);
    onChange && onChange(userArps);
    validateSegmentsWrapper({
      arps: userArps.arps,
      productSegmentTemplate: updatedProductSegmentTemplate
    });
  };

  const switchToSegmentMode = (product: string) => {
    const segmentTemplate = productSegmentTemplate[product];
    const idx = segmentTemplate.productCols.indexOf(product);
    if (idx < 0) {
      return;
    }
    const productArps = arps.arps.filter((segment) => segment.product === product);
    const isRampUp = isRampUpSegment({
      segmentTemplateName: segmentTemplate.name,
      segments: productArps
    });
    const clone = {
      productCols: productArps.map((item) => item.product),
      name: segmentTemplate.name,
      productData: productArps.map((item, i) => {
        const vol =
          isRampUp && i == 0
            ? getRampUpSegmentCumVolume(item)
            : arpsWasm.getSegmentVolume(
                item.qi,
                item.di,
                item.b,
                item.qf,
                BigInt(Math.floor(new Date(item.startDate).getTime() / 1000)),
                BigInt(Math.floor(new Date(item.endDate).getTime() / 1000))
              );

        return {
          "Start Date": item.startDate,
          Qi: item.qi,
          [getDeclineTitleWithType("Di", declineType)]: convertDecline(
            item.di,
            item.b,
            declineType
          ),
          B: item.b,
          [getDeclineTitleWithType("Df", declineType)]: convertDecline(
            item.df,
            item.b,
            declineType
          ),
          Qf: item.qf,
          "End Date": item.endDate,
          Duration: getDurationInYears(item.startDate, item.endDate),
          Volume: (vol * 0.001).toFixed(2)
        };
      }),
      rowHeaders: [
        "Start Date",
        "Duration",
        "Qi",
        getDeclineTitleWithType("Di", declineType),
        "B",
        getDeclineTitleWithType("Df", declineType),
        "Qf",
        "End Date",
        "Volume"
      ],
      displayHeaders: [
        "Start Date",
        "Duration",
        "Q.i",
        "D.i",
        "B",
        "D.f",
        "Q.f",
        "End Date",
        "Volume"
      ]
    } as SegmentTemplate;

    setProductSegmentTemplate({
      [product]: clone
    });
    setMode("Segment");
  };

  const onAddNewProduct = (item) => {
    if (!arps || !overallSegmentTemplate) {
      return;
    }
    const arpsClone = {
      ...arps,
      title: selectedTypeWellTitle,
      reserveCategory: selectedTypeWellRescat
    };
    arpsClone.arps = arpsClone.arps ?? [];
    arpsClone.constants = arpsClone.constants ?? [];

    const previousProductTemplate = Object.values(productSegmentTemplate).pop();
    const isPreviousProductAConstant = isRatio(previousProductTemplate?.productCols[0]);
    const template: SegmentTemplate =
      productSegmentTemplate[item.key] ||
      (!isPreviousProductAConstant && previousProductTemplate) ||
      overallSegmentTemplate;

    const primaryProductSegment: ArpSegment[] = arpsClone.arps.filter((item) =>
      isPrimaryProduct(item.product)
    );

    const primaryProducts = Array.from(
      new Set(primaryProductSegment.map((item) => item.product.toUpperCase()))
    );
    if (primaryProducts.findIndex((p) => p.toUpperCase() === item.key) >= 0) {
      return;
    }
    const newProduct = item.key;
    const newList = isPrimaryProduct(newProduct)
      ? [...arpsClone.arps].concat(
          template.initialData(newProduct, primaryProductSegment[0]?.startDate)
        )
      : [...arpsClone.arps];
    // ensure not null
    const constants = isRatio(newProduct)
      ? [...arpsClone.constants].concat([
          {
            product: newProduct,
            value: 0,
            unit: getDefaultRateUnitsFromString(newProduct),
            uniqueId: arpsClone.uniqueID
          }
        ])
      : [...arpsClone.constants];
    const userArps = {
      ...arpsClone,
      arps: newList,
      constants
    };
    setCalculatedFields({ ...calculatedFields, [newProduct.label]: ["EUR"] });
    onChange && onChange(userArps);
    validateSegmentsWrapper({ arps: userArps.arps, productSegmentTemplate });
  };

  const switchToProductMode = () => {
    if (!arpsWasm) {
      return;
    }

    const updatedProductTemplates = updateProductTemplates({
      arps,
      productSegmentTemplate,
      typeWellTemplates,
      arpsWasm,
      kind,
      setTemplateError,
      cumulativeData,
      declineType,
      typeWellSettings,
      updateEUR
    });
    setProductSegmentTemplate(updatedProductTemplates);
    setMode("Product");
  };

  function getProductInputs({
    product,
    refs,
    productIndex,
    segmentIndex,
    forecastConstant,
    isRatio,
    isSync
  }: {
    product: string;
    refs: MutableRefObject<MutableRefObject<InputRef>[]>;
    productIndex: number;
    segmentIndex: number;
    forecastConstant: boolean;
    isRatio: boolean;
    isSync: boolean;
  }) {
    const uniqueFieldsSet = getUniqueRowAndDisplayHeadersSorted(productSegmentTemplate);
    const segmentNumber = segmentIndex + 1;
    const hasError =
      errors[product] &&
      (mode !== "Segment" || errors[product].includes(`segment ${segmentNumber}`));

    return (
      <ProductColumnWrapper key={product + "" + segmentIndex} width={120}>
        <ProductHeaderContainer>
          {GetProductLabel({
            mode,
            cumulativeData,
            product,
            kind,
            productIndex,
            segmentIndex
          })}
          {hasError ? (
            <Popover content={<div>{errors[product]}</div>}>
              <ErrorWrapper>
                <ErrorOutlineOutlined />
              </ErrorWrapper>
            </Popover>
          ) : null}
          {warnings[product] ? (
            <Popover
              content={
                <div data-testid={"typewell-input-table-warning-popover-" + product}>
                  {warnings[product]}
                </div>
              }>
              <WarningWrapper
                data-testid={"typewell-input-table-warning-wrapper-" + product}>
                <ErrorOutlineOutlined />
              </WarningWrapper>
            </Popover>
          ) : null}
          {mode !== "Segment" && !isSync && (
            <Dropdown
              menu={{
                items: ProductMoreOptions({
                  product,
                  isSync,
                  switchToSegmentMode,
                  setOnTime,
                  deleteProduct,
                  kind,
                  projectPermissions
                })
              }}
              trigger={["click"]}>
              <Button icon={<MoreVert />} shape="circle" type="text" />
            </Dropdown>
          )}
        </ProductHeaderContainer>
        <ColumnContainer>
          {(uniqueFieldsSet.rowHeaders ?? []).map((header: string) => {
            const doesFieldExist =
              productSegmentTemplate[product]?.rowHeaders.includes(header);
            return ArpInput({
              header,
              product: productStringToProductTypeEnum(product),
              mode,
              segmentData: productSegmentTemplate[product]?.productData[segmentIndex],
              isCalculated: calculatedFields[product]?.indexOf(header) >= 0,
              isForecastConstant: forecastConstant,
              isRatio,
              isSync,
              handleReload,
              handleReset,
              handleSave,
              onChange: (val) => {
                header === "Template"
                  ? onSelectedProductTemplateChanged({ product, templateName: val })
                  : header === "EUR"
                  ? debounceUpdate(arps, product, header, val, onArpsValueChange)
                  : onArpsValueChange(arps, product, header, val);
              },
              typeWellTemplates,
              productSegmentTemplateName: productSegmentTemplate[product]?.name,
              fieldExists: doesFieldExist,
              inputRef: refs,
              onBlur: null,
              isDisabled: !projectPermissions.canEditTypeWells,
              kind
            });
          })}
        </ColumnContainer>
      </ProductColumnWrapper>
    );
  }

  const onSelectedProductTemplateChanged = ({
    product,
    templateName
  }: {
    product: string;
    templateName: string;
  }) => {
    const templateIndex = typeWellTemplates.findIndex(
      (item) => item.name === templateName
    );
    if (templateIndex === -1) {
      return;
    }
    const toBeInitialSegment =
      templateName.includes("Ramp-Up") || templateName.includes("Constrained");
    const template = typeWellTemplates[templateIndex];
    const arpsGroupedByProduct = groupArpsSegmentsByProduct(arps.arps);
    const productArps = arpsGroupedByProduct[product];

    if (!productArps) {
      return;
    }

    const extendedProductArps = extendArpsWrapper(
      typeWellTemplates,
      productArps,
      templateName
    );

    if (extendedProductArps.length == 0) {
      return;
    }

    let updatedArps: ArpSegment[];
    try {
      updatedArps = updateArps({
        template,
        productArps: extendedProductArps,
        header: "Qi",
        val: toBeInitialSegment ? extendedProductArps[1].qi : extendedProductArps[0].qi,
        typeWellSettings,
        declineType,
        cumulativeData,
        setCalculatedFields,
        calculatedFields
      });
    } catch (err) {
      setErrors(err);
      return;
    }

    const newArps = [...arps.arps];
    const arpsIndex = arps.arps.findIndex((f) => f.product === product);
    if (arpsIndex >= 0) {
      const oldProductLength = newArps.filter((item) => item.product === product).length;
      newArps.splice(arpsIndex, oldProductLength, ...updatedArps);
    }
    const userArps = Object.assign({}, arps, {
      arps: newArps,
      reserveCategory: selectedTypeWellRescat
    });

    resetCalculatedFields();
    setProductSegmentTemplate((prevProductTemplates) => ({
      ...prevProductTemplates,
      [product]: template
    }));
    onChange && onChange(userArps);
    validateSegmentsWrapper({ arps: userArps.arps, productSegmentTemplate });
  };

  const onSelectedOverallTemplateChanged = (templateName: string) => {
    const templateIndex = typeWellTemplates.findIndex(
      (item) => item.name === templateName
    );
    if (templateIndex === -1) {
      return;
    }
    const toBeInitialSegment =
      templateName.includes("Ramp-Up") || templateName.includes("Constrained");
    const template = typeWellTemplates[templateIndex];
    const arpsGroupedByProduct = groupArpsSegmentsByProduct(arps.arps);
    const keys = Object.keys(arpsGroupedByProduct);
    let allArps = [];
    try {
      for (const product of keys) {
        const productArps = arpsGroupedByProduct[product];

        const extendedProductArps = extendArpsWrapper(
          typeWellTemplates,
          productArps,
          templateName
        );

        if (extendedProductArps.length == 0) {
          continue;
        }
        const updatedArps = updateArps({
          template,
          productArps: extendedProductArps,
          header: "Qi",
          val: toBeInitialSegment ? extendedProductArps[1].qi : extendedProductArps[0].qi,
          typeWellSettings,
          declineType,
          cumulativeData,
          setCalculatedFields,
          calculatedFields
        });
        allArps = allArps.concat(updatedArps);
      }
    } catch (err) {
      setErrors(err);
      return;
    }
    if (allArps.length === 0) {
      return;
    }
    const userArps = Object.assign({}, arps, {
      arps: allArps,
      reserveCategory: selectedTypeWellRescat
    });
    resetCalculatedFields();
    setOverallSegmentTemplate(template);
    // set productSegmentTemplate for all products to the same template name
    const updatedProductSegmentTemplate = { ...productSegmentTemplate };
    for (const product of keys) {
      updatedProductSegmentTemplate[product] = template;
    }
    setProductSegmentTemplate(updatedProductSegmentTemplate);
    onChange && onChange(userArps);
    validateSegmentsWrapper({
      arps: userArps.arps,
      productSegmentTemplate: updatedProductSegmentTemplate
    });
  };

  if (!typeWellTemplates?.length || !overallSegmentTemplate) {
    return <></>;
  }

  function onArpsValueChange(
    forecast: ICheckedForecast,
    product: string,
    header: string,
    val
  ) {
    try {
      if (!forecast || !forecast.arps) {
        return;
      }
      setErrors({});
      setWarnings({});
      const productArps = forecast.arps.filter((f) => f.product === product);

      const arpsIndex = forecast.arps.findIndex((f) => f.product === product);
      const constantIndex = (forecast.constants ?? []).findIndex(
        (f) => f.product.toUpperCase() === product.toUpperCase()
      );
      if (arpsIndex < 0 && constantIndex < 0) {
        return;
      }
      const newList = [...forecast.arps];
      if (arpsIndex >= 0) {
        try {
          const productTemplate = productSegmentTemplate[product];
          const newArps = updateArps({
            template: productTemplate,
            productArps,
            header,
            val,
            typeWellSettings,
            declineType,
            cumulativeData,
            setCalculatedFields,
            calculatedFields
          });
          newList.splice(arpsIndex, productArps.length, ...newArps);
        } catch (err) {
          setErrors(err);
          return;
        }
      }
      const newConstants = [...(forecast.constants ?? [])];
      if (constantIndex >= 0) {
        const existingConstant = newConstants[constantIndex];
        newConstants.splice(constantIndex, 1, {
          value: val,
          product: existingConstant.product,
          uniqueId: existingConstant.uniqueId,
          unit: existingConstant.unit
        });
      }
      const userArps = {
        ...forecast,
        arps: newList,
        constants: newConstants
      };
      if (userArps?.arps) {
        validateSegmentsWrapper({ arps: userArps.arps, productSegmentTemplate });
      }
      onChange && onChange(userArps);
    } catch (err) {
      setErrors({ [product]: "An error occurred." });
    }
  }

  return (
    <RootContainer data-testid="type-well-input-table">
      <ErrorBoundary>
        <TemplateSelectorWrapper>
          {mode === "Product" ? (
            <RowHeading>Overall Template</RowHeading>
          ) : (
            <SwitchToProductModeWrapper>
              <Button icon={<ArrowBack />} type="primary" onClick={switchToProductMode}>
                Back
              </Button>
            </SwitchToProductModeWrapper>
          )}
          {templateError && (
            <Popover content={<div>{templateError}</div>}>
              <ErrorWrapper>
                <ErrorOutlineOutlined />
              </ErrorWrapper>
            </Popover>
          )}
          {mode === "Product" ? (
            <Tooltip
              placement="top"
              title={
                Object.values(productSegmentTemplate).every(
                  (template) => template.productCols.length === 0
                )
                  ? "Select a product first"
                  : ""
              }>
              <Select
                className="template-select"
                value={getOverallTemplateName({
                  overallSegmentTemplate,
                  productSegmentTemplate
                })}
                onChange={onSelectedOverallTemplateChanged}
                options={typeWellTemplates.map((item) => ({
                  label: item.name,
                  value: item.name
                }))}
                disabled={
                  hasSource ||
                  Object.keys(productSegmentTemplate).length === 0 ||
                  Object.values(productSegmentTemplate).some(
                    (template) => template.productCols.length === 0
                  ) ||
                  !projectPermissions.canEditTypeWells
                }
              />
            </Tooltip>
          ) : (
            <strong>
              {Object.values(productSegmentTemplate).some(
                (template) => template.productCols.length > 0
              )
                ? Object.values(productSegmentTemplate).find(
                    (template) => template.productCols.length > 0
                  )?.productCols[0]
                : null}
              {" Segments"}
            </strong>
          )}

          {mode === "Product" ? (
            <Tooltip
              placement="top"
              title={
                Object.values(productSegmentTemplate).every(
                  (template) => template.productCols.length === 0
                )
                  ? "Select a product first"
                  : ""
              }>
              <Select
                className="rescat-select"
                value={selectedTypeWellRescat}
                onChange={(e) => {
                  dispatch(
                    setSelectedTypeWell({
                      ...selectedTypeWell,
                      reserveCategory: e
                    })
                  );
                  dispatch(setIsTypeWellSavable(true));
                }}
                options={[
                  { label: "", value: "" },
                  ...RESERVE_CATEGORIES.map((rc) => ({
                    label: rc,
                    value: rc
                  }))
                ]}
                disabled={
                  hasSource ||
                  Object.keys(productSegmentTemplate).length === 0 ||
                  Object.values(productSegmentTemplate).some(
                    (template) => template.productCols.length === 0
                  ) ||
                  !projectPermissions.canEditTypeWells
                }
              />
            </Tooltip>
          ) : (
            <></>
          )}

          {mode === "Product" && kind === "Forecasting" && (
            <ButtonIconCentered
              disabled={
                hasSource ||
                Object.keys(productSegmentTemplate).length === 0 ||
                Object.values(productSegmentTemplate).some(
                  (template) => template.productCols.length === 0
                ) ||
                !projectPermissions.canEditTypeWells
              }
              icon={<Icon path={mdiClock} size={1.0} />}
              onClick={() => setOnTime({ ...onTime, open: true })}>
              On Time
            </ButtonIconCentered>
          )}
        </TemplateSelectorWrapper>
        <Wrapper>
          <TableContainer>
            <ProductColumnContainer>
              <ProductColumnWrapper>
                <ColumnContainer>
                  {/*Header Column*/}
                  <RowHeading />
                  {(uniqueProductFields.rowHeaders ?? []).map((header, idx) => {
                    // Normal text will be before the dot
                    // Subscript text will be after the dot
                    const displayHeader =
                      uniqueProductFields.displayHeaders[idx]?.split(".");
                    const headerUnit = header.split(" ");
                    return (
                      <RowHeading key={header} className="header" width={80}>
                        {displayHeader[0]}{" "}
                        {displayHeader.length > 1 ? (
                          <sub>
                            {displayHeader[1]}{" "}
                            {headerUnit.length > 1 ? headerUnit[1] : null}
                          </sub>
                        ) : null}
                      </RowHeading>
                    );
                  })}
                </ColumnContainer>
              </ProductColumnWrapper>

              {/*Product Columns*/}
              {Object.keys(productSegmentTemplate).map((product, productIndex) => {
                const productDataArray =
                  productSegmentTemplate[product]?.productData || [];
                return productDataArray.map((_productData, dataIndex) => {
                  return getProductInputs({
                    product,
                    refs: inputRefs,
                    productIndex,
                    segmentIndex: dataIndex,
                    forecastConstant: arps?.constants?.some((p) => p.product === product),
                    isRatio: isRatio(product),
                    isSync: hasSource
                  });
                });
              })}
              {mode === "Product" && !hasSource && !templateError && (
                <NewProductWrapper width={120}>
                  <Dropdown
                    disabled={!projectPermissions.canEditTypeWells}
                    menu={{ items: products, onClick: onAddNewProduct }}
                    trigger={["click"]}>
                    <Button
                      data-testid="add-new-tw-product-button"
                      type="primary"
                      icon={<Add />}>
                      Product <ChevronDown />
                    </Button>
                  </Dropdown>
                </NewProductWrapper>
              )}
            </ProductColumnContainer>
          </TableContainer>
        </Wrapper>
        <OnTimeEditorModal
          {...onTime}
          onTime={arps?.onTimeArray ?? []}
          startDate={
            arps?.arps && arps?.arps.length > 0 && arps?.arps[0].startDate.length > 12
              ? new Date(arps.arps[0].startDate).getTime()
              : Date.now()
          }
          onCancel={() => setOnTime({ ...onTime, open: false })}
          onOk={(onTimeArray) => {
            onOnTimeChange && onTimeArray && onOnTimeChange(onTimeArray);
            setOnTime({ ...onTime, open: false });
          }}
        />
      </ErrorBoundary>
      {saveStatus?.error && (
        <StyledAlert type="error" message={saveStatus.error.toString()} />
      )}
    </RootContainer>
  );
}

const StyledAlert = styled(Alert)`
  margin-top: 10px;
  height: 100px;
`;

const ErrorWrapper = styled.div`
  display: flex;
  color: red;
  align-items: center;
  justify-content: center;
`;

const WarningWrapper = styled.div`
  display: flex;
  color: orange;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  position: relative;
  overflow: auto;
  height: 100%;
  white-space: nowrap;
  flex-grow: 1;

  .product-select {
    .ant-select {
      min-width: 180px;
    }
  }
`;

const ProductHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  .ant-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 26px !important;
    width: 26px;
    height: 26px;

    &.ant-btn-circle {
      border-radius: 50%;
    }

    &:hover {
      background-color: white;
    }
  }

  .ant-select {
    width: 100px;
  }

  gap: 0;
`;

const RowHeading = styled.div`
  min-width: 80px;
  width: 120px;
  max-width: 120px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-height: 27px;
  padding-right: 5px;
  background-color: white;

  &:last-of-type {
    min-height: 26px;
  }
`;

const ProductColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 120px;
  max-width: 120px;
  min-width: 120px;
  justify-content: flex-start;

  &:first-child {
    min-width: 120px;
    max-width: 120px;
    position: sticky;
    left: 0;
    z-index: 10;
    height: 28px;
    width: 100%;
    background-color: white;
    display: inline-block;
  }
`;

const SwitchToProductModeWrapper = styled.div`
  display: flex;
  flex-direction: column;

  .ant-btn {
    display: flex;
    align-items: center;
  }
`;

const NewProductWrapper = styled.div`
  .ant-btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const TableContainer = styled.div`
  font-size: 1.3rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .ant-btn {
    max-height: 26px;
  }

  .ant-select {
    height: 26px;
    font-size: 1.3rem;

    .ant-select-selection-search {
      display: flex;
    }

    .ant-select-selection-item {
      display: flex;
      align-items: center;
    }

    .ant-select-selector {
      height: 26px;
    }
  }

  .ant-input {
    padding: 0 3px;
    font-size: 1.3rem;
    height: 27px;
  }

  .ant-picker {
    padding: 0 5px;
  }

  .ant-picker-input > input {
    font-size: 1.3rem;
    height: 25px;
  }

  .sticky-col {
    position: sticky;
    background-color: white;
  }
`;

const TemplateSelectorWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  padding: 5px 0;

  .template-select {
    flex: 0 0 50%;
  }

  .rescat-select {
    flex: 0 0 20%;
  }
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ProductColumnContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
`;

const RootContainer = styled.div`
  max-width: 100%;
  display: flex;
  flex-grow: 1;
  overflow-x: visible;
  flex-direction: column;
`;
