import { useEffect, useState } from "react";

import { useChartsContext } from "contexts";

import { useChartDependencies, useChartEntities } from "hooks/charts";

import { EntityKind } from "models/entityKind";

import { applyRemoveUUID } from "components/chart/utils";
import { addNormalizationBasisToTitle } from "components/chart/utils/addNormalizationBasisToTitle";
import { useScreenshotContext } from "components/screenshot/hooks";
import { applyAbbreviations } from "components/screenshot/utils";
import { useUserSettings } from "components/user/hooks";

const useChartLegendTitle = (): string => {
  // state from store
  const { id, entityKind } = useChartsContext();
  const { screenshot, chartFocus, normalizeBy } = useChartEntities(id);
  const isChartFocusActive = chartFocus?.active;
  const isNormalizeByActive = normalizeBy?.active;
  const isScreenshotActive = screenshot?.active;
  const chartFocusTitle = chartFocus?.properties?.groupBy.title;

  const { groupBy, txnId } = useChartDependencies(entityKind);

  const { userAbbreviations } = useUserSettings();
  const screenshotState = useScreenshotContext();

  // state
  const [value, setValue] = useState<string>("");

  const abbreviations = userAbbreviations?.abbreviations ?? [];

  useEffect(() => {
    const legendTitle =
      entityKind == EntityKind.Well ? txnId.legend.title : txnId.facilityLegend.title;
    // const chart = getChart(chartType);
    // TODO chart: Set up for Total Rate Date and Well Contribution
    // if (chart?.getCustomLegendTitle(chartState)) {
    //   setValue(chart.getCustomLegendTitle(chartState));
    //   return;
    // }

    if (!legendTitle) {
      return;
    }

    let title = isChartFocusActive ? chartFocusTitle : groupBy.title;

    const isCustomField =
      groupBy.property.startsWith("Org_Focus_Fields") ||
      groupBy.property.startsWith("My_Focus_Fields");

    title =
      isCustomField && groupBy.defaultUnit
        ? `${groupBy.title} (${groupBy.defaultUnit})`
        : title;

    const isForecastEnabled = title.includes("Forecast");

    let nextTitle = isForecastEnabled ? applyRemoveUUID(title) : title;
    nextTitle = isNormalizeByActive
      ? addNormalizationBasisToTitle(title, normalizeBy?.properties)
      : nextTitle;

    const formattedTitle =
      isScreenshotActive && screenshotState?.settings?.applyAbbreviations
        ? applyAbbreviations(nextTitle, abbreviations)
        : nextTitle;

    setValue(formattedTitle);
  }, [
    groupBy,
    txnId.legend.title,
    // TODO chart: add when Total Rate Date is available
    // chartState.settings?.otherProducts,
    isChartFocusActive,
    chartFocusTitle,
    isNormalizeByActive,
    isScreenshotActive,
    normalizeBy,
    abbreviations
  ]);

  return value;
};

export default useChartLegendTitle;
