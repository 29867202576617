import React from "react";

import { RateDto } from "arps_wasm";
import { ChartTypeLabels } from "constants/chart.constants";
import { TimeStep } from "constants/charts.enums";
import dayjs from "dayjs";

import {
  ArpSegment,
  ArpsDesignerKind,
  UpdateProductTemplatesParams
} from "../../../models/UserArpsModel";
import { ICheckedForecast } from "../../../store/features";
import { isPrimaryProduct } from "../../../utils";
import { CumulativeData } from "../../forecasting/Forecasting";
import { UpdateEURParams } from "../hooks/useUpdateEUR";
import {
  ProductData,
  ProductSegmentTemplate,
  SegmentTemplate
} from "../models/SegmentTemplate";
import { TypeWellProductItem } from "../widget/TypeWellEditor/tabs/TypeWellProductOptions";
import { arpsWasm } from "./UpdateSegment";
import {
  getTypewellTemplateFields,
  isConstrainedSegment,
  isRampUpSegment
} from "./arpsUtils";
import { getNewCalculatedFields } from "./calculatedFieldsUtils";
import { getFractionalMonths, isNextMonth, numberOfMonthsAfterDate } from "./dates";
import { getDeclineTitleWithType } from "./declineHelpers";
import { scaleDataBasedOnYAxisUnits } from "./scaleDataBasedOnYAxisUnits";

const TYPE_WELL_MAX_NUMBER_OF_YEARS_CAP = 100;

export function calculateTimeForTimeSeriesCharts(
  yAxisTitle: string,
  rateCum: RateDto[],
  chartType: "Rate Time" | "Cum Time",
  timeStep: TimeStep
): [number, number, number | undefined][] {
  const scale = scaleDataBasedOnYAxisUnits(yAxisTitle);
  const { series } = rateCum.reduce(
    (acc, rc) => {
      const currentDate = new Date(Date.UTC(rc.year, rc.month - 1, rc.day));
      const incrementMonth =
        timeStep == TimeStep.Day || isNextMonth(acc.lastDate, currentDate);
      if (incrementMonth || acc.lastDate == null) {
        acc.monthIdx++;
      }
      acc.lastDate = currentDate;
      const nextTimeValue =
        timeStep == TimeStep.Day
          ? acc.monthIdx
          : getFractionalMonths(dayjs.utc(currentDate), acc.monthIdx);

      acc.series.push([
        nextTimeValue,
        chartType == "Rate Time" ? rc.rate : rc.cum * scale,
        undefined
      ]);

      return acc;
    },
    { series: [], lastDate: null, monthIdx: -1 } as {
      series: [number, number, number | undefined][];
      lastDate: Date | null;
      monthIdx: number;
    }
  );

  return series;
}

export function getTypeWellSeries(
  chartType: ChartTypeLabels,
  rateCum: RateDto[],
  yAxisTitle: string,
  timeStep: TimeStep
): [number | string, number, number | undefined][] {
  if (chartType == "Rate Cum") {
    return rateCum.map((rc) => {
      return [rc.cum * 0.001, rc.rate, undefined];
    });
  }

  if (chartType == "Rate Time" || chartType == "Cum Time") {
    return calculateTimeForTimeSeriesCharts(yAxisTitle, rateCum, chartType, timeStep);
  }

  if (chartType == "Rate Date") {
    const scale = scaleDataBasedOnYAxisUnits(yAxisTitle);
    const series = [];

    const date = new Date();
    const maxYear = date.getFullYear() + TYPE_WELL_MAX_NUMBER_OF_YEARS_CAP;

    rateCum
      .filter((rc, idx) => {
        return (
          idx > 0 &&
          (rc.year < maxYear || (rc.year === maxYear && rc.month === 1 && rc.day === 1))
        );
      })
      .forEach((rc) => {
        series.push([
          rc.year +
            "-" +
            rc.month.toString().padStart(2, "0") +
            "-" +
            rc.day.toString().padStart(2, "0") +
            "T00:00:00",
          rc.rate * scale,
          undefined
        ]);
      });

    return series;
  }
}

export function getAvailableProducts(
  arps: ICheckedForecast,
  typeWellProductsMenuItems: TypeWellProductItem[]
) {
  const arpsProductsToRemove = [
    ...new Set(arps?.arps?.map((item) => item.product)),
    ...new Set(arps?.constants?.map((item) => item.product))
  ];

  const hasCondensate =
    arpsProductsToRemove.indexOf("CONDENSATE") >= 0 ||
    arpsProductsToRemove.indexOf("COND.") >= 0;
  const hasOil = arpsProductsToRemove.indexOf("OIL") >= 0;
  const hasWater = arpsProductsToRemove.indexOf("WATER") >= 0;
  const hasOGR = arpsProductsToRemove.indexOf("OGR") >= 0;
  const hasCGR = arpsProductsToRemove.indexOf("CGR") >= 0;
  const hasWGR = arpsProductsToRemove.indexOf("WGR") >= 0;
  const hasGOR = arpsProductsToRemove.indexOf("GOR") >= 0;
  const hasGas = arpsProductsToRemove.indexOf("GAS") >= 0;
  const hasSalesGas = arpsProductsToRemove.indexOf("SALES GAS") >= 0;
  const hasWOR = arpsProductsToRemove.indexOf("WOR") >= 0;
  if (arpsProductsToRemove.length == 0) {
    //don't include ratios if no primary product
    arpsProductsToRemove.push("CGR");
    arpsProductsToRemove.push("OGR");
    arpsProductsToRemove.push("WGR");
    arpsProductsToRemove.push("GOR");
    arpsProductsToRemove.push("WOR");
    arpsProductsToRemove.push("O+W");
    arpsProductsToRemove.push("C2");
    arpsProductsToRemove.push("C3");
    arpsProductsToRemove.push("C4");
    arpsProductsToRemove.push("C5");
    arpsProductsToRemove.push("SHRINKAGE");
    arpsProductsToRemove.push("1+WOR");
  }
  if (hasOGR || hasCGR) {
    arpsProductsToRemove.push("OIL");
    arpsProductsToRemove.push("CONDENSATE");
  }
  if (hasCondensate) {
    arpsProductsToRemove.push("CONDENSATE");
    arpsProductsToRemove.push("OIL");
    arpsProductsToRemove.push("CGR");
    arpsProductsToRemove.push("OGR");
  }
  if (hasOil) {
    arpsProductsToRemove.push("CONDENSATE");
    arpsProductsToRemove.push("CGR");
    arpsProductsToRemove.push("OGR");
  } else {
    arpsProductsToRemove.push("WOR");
  }
  if (!hasCondensate && !hasOil) {
    arpsProductsToRemove.push("GOR");
  }
  if (hasWater) {
    arpsProductsToRemove.push("WOR");
    arpsProductsToRemove.push("WGR");
  }
  if (hasGOR) {
    arpsProductsToRemove.push("GAS");
  }
  if (hasWOR) {
    arpsProductsToRemove.push("WATER");
    arpsProductsToRemove.push("WGR");
  }
  if (hasWGR) {
    arpsProductsToRemove.push("WATER");
    arpsProductsToRemove.push("WOR");
  }
  if (hasGas) {
    arpsProductsToRemove.push("GOR");
    arpsProductsToRemove.push("O+W");
  } else {
    arpsProductsToRemove.push("WGR");
  }

  if (hasSalesGas && hasGas) {
    arpsProductsToRemove.push("SHRINKAGE");
  }

  return [
    ...typeWellProductsMenuItems.filter(
      (item) => arpsProductsToRemove.indexOf(item.key ?? "") < 0
    )
  ];
}

function getArpsSegmentTemplate({
  segments,
  typeWellTemplates
}: {
  segments: ArpSegment[];
  typeWellTemplates: SegmentTemplate[];
}): SegmentTemplate | undefined {
  const segmentLength = segments.length;

  if (segmentLength === 1) {
    return typeWellTemplates.find((template) => template.name.includes("One Seg."));
  }

  const hasRampUpSegment = isRampUpSegment({ segmentTemplateName: null, segments });
  const hasConstrainedSegment = isConstrainedSegment({
    segments,
    segmentTemplateName: null
  });

  if (segmentLength === 2) {
    return typeWellTemplates.find((template) =>
      template.name.includes("Two Seg. Hyperbolic")
    );
  }

  if (segmentLength === 3) {
    if (hasRampUpSegment) {
      return typeWellTemplates.find((template) =>
        template.name.includes("Two Seg. Hyperbolic with Ramp-Up")
      );
    }
    return typeWellTemplates.find((template) =>
      template.name.includes("Three Seg. Transient")
    );
  }

  if (segmentLength === 4) {
    if (hasConstrainedSegment) {
      return typeWellTemplates.find((template) =>
        template.name.includes("Three Seg. Transient with Constrained Period")
      );
    }
    if (hasRampUpSegment) {
      return typeWellTemplates.find((template) =>
        template.name.includes("Three Seg. Transient with Ramp-Up")
      );
    }
  }

  return undefined;
}

export const validateSegments = ({
  arpsSegments,
  productSegmentTemplate,
  kind,
  cumulativeData,
  primarySegmentEndDate,
  isPrimaryTypeWellSegment
}: {
  arpsSegments: ArpSegment[];
  productSegmentTemplate: ProductSegmentTemplate;
  kind: ArpsDesignerKind;
  cumulativeData: CumulativeData;
  primarySegmentEndDate: Date;
  isPrimaryTypeWellSegment: boolean;
}) => {
  const errors = [];
  const warnings = [];

  const product = arpsSegments[0]?.product;

  if (!product) {
    return {
      errors,
      warnings
    };
  }

  const segmentTemplate = productSegmentTemplate[product];
  const segmentTemplateName = segmentTemplate?.name;

  const hasRampUpSegment = isRampUpSegment({
    segments: arpsSegments,
    segmentTemplateName
  });
  const foundErrors = arpsWasm.checkSegmentsForError(arpsSegments, hasRampUpSegment);
  for (const err of foundErrors) {
    errors.push(`Segment ${err.segment}: ${err.error}`);
  }
  for (const segment of arpsSegments) {
    const product = segment.product.toLowerCase();
    const segmentNumber = arpsSegments.indexOf(segment) + 1;

    if (segment.di === 0) {
      warnings.push(`Invalid ${product} segment ${segmentNumber}: no decline (di is 0).`);
    }

    //check end dates
    try {
      const endDate = new Date(Date.parse(segment.endDate));
      const dateDiff = endDate.getUTCFullYear() - new Date().getUTCFullYear();
      if (dateDiff > 100) {
        warnings.push(
          `Invalid ${product} segment ${segmentNumber} end date: segments run past 100 years.`
        );
      }

      const isGreaterThanSixMonths =
        numberOfMonthsAfterDate(primarySegmentEndDate, endDate) >= 6;

      const hasTypeWellWarning: boolean =
        kind === "TypeWell" &&
        primarySegmentEndDate &&
        !isPrimaryTypeWellSegment &&
        isGreaterThanSixMonths;

      const hasForecastWarning: boolean =
        kind === "Forecasting" &&
        primarySegmentEndDate &&
        segment.product !== cumulativeData?.primaryProduct &&
        isGreaterThanSixMonths;

      if (hasTypeWellWarning || hasForecastWarning) {
        warnings.push("Warning: Primary product end date is 6+ months earlier");
      }
    } catch (err) {
      warnings.push(
        `Invalid ${product} segment ${segmentNumber} end date: segments run past 100 years.`
      );
    }

    if (segment.b > 2) {
      warnings.push("Warning: b values higher than 2 may cause an error");
    }

    // check for forecast date < last production date
    if (kind === "Forecasting" && cumulativeData && cumulativeData.lastProductionDate) {
      const lastProdDate = new Date(Date.parse(cumulativeData.lastProductionDate));
      const forecastDate = new Date(Date.parse(segment.startDate));
      if (forecastDate < lastProdDate) {
        warnings.push("Warning: forecast start date is before production ends");
      }
    }
  }
  return {
    errors,
    warnings
  };
};

export function getUpdatedHeader(header: string, declineType: string) {
  if (header === "Di" || header === "D1f" || header === "Df" || header === "D2f") {
    return getDeclineTitleWithType(header, declineType);
  } else {
    return header;
  }
}

export function updateHeaders(template: SegmentTemplate, declineType: string) {
  const updatedRowHeaders = template.rowHeaders.map((header: string) => {
    return getUpdatedHeader(header, declineType);
  });

  const updatedTemplate = {
    ...template
  };
  updatedTemplate.rowHeaders = updatedRowHeaders;

  return updatedTemplate;
}

export const getOverallTemplateName = ({
  productSegmentTemplate,
  overallSegmentTemplate
}: {
  productSegmentTemplate: ProductSegmentTemplate;
  overallSegmentTemplate?: SegmentTemplate;
}): string => {
  const templates: SegmentTemplate[] = Object.values(productSegmentTemplate);
  if (templates.length === 0) {
    return overallSegmentTemplate?.name;
  }
  const firstTemplate = templates[0]?.name;
  const allSame = templates.every(
    (template) => template?.name === firstTemplate || !template?.name
  );
  return allSame ? firstTemplate : "Mixed Templates";
};

export const updateArps = ({
  template,
  productArps,
  header,
  val,
  typeWellSettings,
  declineType,
  cumulativeData,
  setCalculatedFields,
  calculatedFields
}: {
  template: SegmentTemplate;
  productArps: ArpSegment[];
  header: string;
  val: number | string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  typeWellSettings: any;
  declineType: string;
  cumulativeData: CumulativeData;
  setCalculatedFields: React.Dispatch<
    React.SetStateAction<{ [product: string]: string[] }>
  >;
  calculatedFields: { [product: string]: string[] };
}): ArpSegment[] => {
  if (productArps.length == 0 || !arpsWasm) {
    return [];
  }
  try {
    const defaultTypeWellSettings = typeWellSettings.settings?.find(
      (s: { product: string }) => s.product === productArps[0].product
    );

    const beforeUpdate = getTypewellTemplateFields(
      template.name,
      productArps,
      declineType,
      defaultTypeWellSettings
    );

    const updatedArps = template.update(
      template.name,
      productArps,
      declineType,
      header,
      val,
      cumulativeData
    );
    if (updatedArps && updatedArps[0].di === null) {
      return updatedArps;
    }

    const afterUpdate = getTypewellTemplateFields(
      template.name,
      updatedArps,
      declineType,
      defaultTypeWellSettings
    );

    const newCalculatedFields = getNewCalculatedFields(beforeUpdate, afterUpdate, header);

    if (header !== "EUR") {
      setCalculatedFields(() => {
        newCalculatedFields.push("EUR");
        return { ...calculatedFields, [productArps[0].product]: newCalculatedFields };
      });
      return updatedArps;
    }

    setCalculatedFields(() => {
      return { ...calculatedFields, [productArps[0].product]: newCalculatedFields };
    });

    return updatedArps;
  } catch (err) {
    throw { [productArps[0].product]: err?.toString() };
  }
};

export const extendArpsWrapper = (
  segmentTemplates: SegmentTemplate[],
  arps: ArpSegment[],
  template: string
): ArpSegment[] => {
  const templateSegment = segmentTemplates.find((item) => item.name === template);
  if (arps.length === 0) {
    return arps;
  }
  return templateSegment.extendArps(arps);
};

export const PRODUCTS = {
  OIL: "OIL",
  GAS: "GAS",
  WATER: "WATER",
  CONDENSATE: "CONDENSATE",
  COND: "COND."
} as const;

const PRODUCT_NAMES = [
  PRODUCTS.OIL,
  PRODUCTS.GAS,
  PRODUCTS.WATER,
  PRODUCTS.CONDENSATE
] as const;

export const getDefaultTypeWellTemplate = (params: {
  kind: ArpsDesignerKind;
  typeWellTemplates: SegmentTemplate[] | undefined;
}): SegmentTemplate | undefined => {
  if (params.kind === "Forecasting") {
    return params.typeWellTemplates[1];
  }
  return params.typeWellTemplates[0];
};

// TODO: Refactor this function. It's doing too much. And getData is not a good name for it.
export const getData = ({
  arps,
  arpsLib,
  segmentTemplateName,
  kind,
  setTemplateError,
  typeWellTemplates,
  cumulativeData,
  declineType,
  typeWellSettings,
  updateEUR
}: {
  arps: ICheckedForecast;
  arpsLib: typeof import("wasm/arps");
  segmentTemplateName: string;
  kind: ArpsDesignerKind;
  setTemplateError: React.Dispatch<React.SetStateAction<string | undefined>>;
  typeWellTemplates: SegmentTemplate[];
  cumulativeData: CumulativeData;
  declineType: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  typeWellSettings: any;
  updateEUR: (params: UpdateEURParams) => {
    eur: string;
    remaining: string;
  };
}): SegmentTemplate => {
  setTemplateError(undefined);
  if (!arps || !arps.arps || !arps.arps.length) {
    return getDefaultTypeWellTemplate({ kind, typeWellTemplates });
  }

  const primaryProductSegments = [...arps.arps].sort((item1, item2) =>
    isPrimaryProduct(item1.product) === isPrimaryProduct(item2.product)
      ? 0
      : isPrimaryProduct(item1.product)
      ? -1
      : 1
  );

  const productGroup: { [product: string]: ArpSegment[] } = {};
  if (!primaryProductSegments) {
    return typeWellTemplates[1];
  }
  for (const segment of primaryProductSegments) {
    if (!(segment.product in productGroup)) {
      productGroup[segment.product] = [];
    }
    productGroup[segment.product].push(segment);
  }

  const maxSegments = Object.values(productGroup).reduce((longest, current) => {
    return current.length > longest.length ? current : longest;
  }, [] as ArpSegment[]);

  const foundSegment = typeWellTemplates.find(
    (item) =>
      item.numberOfSegments === maxSegments.length &&
      item.name.includes("Ramp-Up") ===
        isRampUpSegment({ segmentTemplateName, segments: maxSegments })
  );

  if (!foundSegment) {
    if (maxSegments.length > 0) {
      // Warn the user that the number of segments is not supported
      setTemplateError("Type well format is not editable.");
    }

    // If no segment template is found, use the second one
    return typeWellTemplates[1];
  }
  const clonedDataTemplates = Object.assign({}, foundSegment);
  clonedDataTemplates.productData = [];

  clonedDataTemplates.productCols = Object.keys(productGroup);

  for (const product of clonedDataTemplates.productCols) {
    const productSegments = productGroup[product] as ArpSegment[];
    if (!productSegments || !arpsLib) {
      continue;
    }

    if (productSegments.length > 0) {
      const defaultTypeWellSettings = typeWellSettings.settings?.find(
        (s) =>
          s.product === productSegments[0].product ||
          (s.product == PRODUCTS.CONDENSATE && product == PRODUCTS.COND)
      );

      clonedDataTemplates.productData.push(
        getTypewellTemplateFields(
          segmentTemplateName,
          productSegments,
          declineType,
          defaultTypeWellSettings
        )
      );

      const lastProductDataIndex = clonedDataTemplates.productData.length - 1;

      // Add the cumToDate production data for primary products
      if (kind === "Forecasting" && lastProductDataIndex >= 0) {
        setCumulativeToDate(
          product,
          clonedDataTemplates,
          lastProductDataIndex,
          cumulativeData
        );
      }
    }
  }

  // Add the historical cumulative production to the predicted EUR from the forecast,
  // and add the newly added cumToDate to the input table
  if (kind === "Forecasting") {
    PRODUCT_NAMES.forEach((product) => {
      const index = clonedDataTemplates.productCols.indexOf(product);
      if (index >= 0) {
        const productData = clonedDataTemplates.productData[index] as ProductData;
        const { eur, remaining } = updateEUR({
          productName: product,
          cumulativeData,
          arps,
          forecastEur: parseFloat(productData.EUR)
        });
        productData.TotalEUR = eur;
        productData.EUR = remaining;
      }
    });
    const eurIndex = clonedDataTemplates.rowHeaders.indexOf("EUR");
    if (eurIndex !== -1) {
      clonedDataTemplates.displayHeaders[eurIndex] = "Remaining";
    }

    if (eurIndex !== -1 && !clonedDataTemplates.rowHeaders.includes("CTD")) {
      clonedDataTemplates.rowHeaders.splice(eurIndex, 0, "CTD");
      clonedDataTemplates.displayHeaders.splice(eurIndex, 0, "CTD");
    }

    if (!clonedDataTemplates.rowHeaders.includes("TotalEUR")) {
      clonedDataTemplates.rowHeaders.push("TotalEUR");
      clonedDataTemplates.displayHeaders.push("EUR");
    }
  }
  return clonedDataTemplates;
};

const setCumulativeToDate = (
  product: string,
  clonedDataTemplates: SegmentTemplate,
  lastIndex: number,
  cumulativeData?: CumulativeData
) => {
  const productMap = {
    [PRODUCTS.OIL]: cumulativeData?.oil,
    [PRODUCTS.CONDENSATE]: cumulativeData?.oil,
    [PRODUCTS.GAS]: cumulativeData?.gas,
    [PRODUCTS.WATER]: cumulativeData?.water,
    [PRODUCTS.COND]: cumulativeData?.condensate
  };

  if (product in productMap) {
    (clonedDataTemplates.productData[lastIndex] as ProductData).CTD =
      productMap[product] ?? 0;
  }
};

export const getSegmentTypeUsingArps = ({
  arps,
  typeWellTemplates
}: {
  arps: ICheckedForecast;
  typeWellTemplates: SegmentTemplate[];
}): ProductSegmentTemplate => {
  if (!arps?.arps) {
    return {};
  }

  const productGroup: { [product: string]: ArpSegment[] } = {};
  for (const segment of arps.arps) {
    if (!(segment.product in productGroup)) {
      productGroup[segment.product] = [];
    }
    productGroup[segment.product].push(segment);
  }

  const updatedProductTemplates: ProductSegmentTemplate = {};

  for (const product in productGroup) {
    const segments = productGroup[product];
    const template = getArpsSegmentTemplate({
      segments,
      typeWellTemplates
    });

    if (template) {
      updatedProductTemplates[product] = template;
    }
  }
  return updatedProductTemplates;
};

export function getAbbreviatedSegmentLabel(segmentName: string): string {
  let label: string;
  switch (true) {
    case segmentName === "One Seg.":
      label = "1 Seg.";
      break;
    case segmentName === "Two Seg. Hyperbolic":
      label = "2 Seg.";
      break;
    case segmentName === "Two Seg. Hyperbolic with Ramp-Up":
      label = `2 Seg. RU`;
      break;
    case segmentName === "Three Seg. Transient":
      label = `3 Seg.`;
      break;
    case segmentName === "Three Seg. Transient with Ramp-Up":
      label = `3 Seg. RU`;
      break;
    case segmentName === "Three Seg. Transient with Constrained Period":
      label = `3 Seg. CP`;
      break;
    default:
      label = segmentName;
  }
  return label;
}

/*
  This function takes a ProductSegmentTemplate object and returns an object containing
  unique row headers and their corresponding display headers.

  1. The order of row headers is based on the longest template.
  2. Row headers from shorter templates that do not appear in the longest template are inserted at their correct position
     based on their original position in the shorter template.
 */
export function getUniqueRowAndDisplayHeadersSorted(
  productSegmentTemplate: ProductSegmentTemplate
): { rowHeaders: string[]; displayHeaders: string[] } {
  const longestTemplate = Object.values(productSegmentTemplate).reduce(
    (prev, curr) => (prev.rowHeaders.length > curr.rowHeaders.length ? prev : curr),
    { rowHeaders: [], displayHeaders: [] }
  );

  const longestRowHeaders = longestTemplate.rowHeaders;
  const longestDisplayHeaders = longestTemplate.displayHeaders;

  const uniqueFieldsMap = new Map<string, string>();

  // Add fields from the longest template first, preserving order.
  longestRowHeaders.forEach((header, index) => {
    uniqueFieldsMap.set(header, longestDisplayHeaders[index]);
  });

  // Process each template to insert extra fields that are missing from the longest template.
  // Inserts extra fields from shorter templates at their correct template index.
  Object.values(productSegmentTemplate).forEach((template) => {
    const templateRowHeaders = template.rowHeaders;
    const templateDisplayHeaders = template.displayHeaders;

    templateRowHeaders.forEach((header, index) => {
      if (!longestRowHeaders.includes(header) && !uniqueFieldsMap.has(header)) {
        const previousHeader = templateRowHeaders[index - 1];
        const previousHeaderIndexInLongest = longestRowHeaders.indexOf(previousHeader);
        let insertIndex: number;

        const isValidPreviousHeader = previousHeaderIndexInLongest !== -1;
        if (isValidPreviousHeader) {
          insertIndex = previousHeaderIndexInLongest + 1;
        } else {
          // Insert at the position corresponding with the shorter template's index.
          insertIndex = index;
        }

        const keys = Array.from(uniqueFieldsMap.keys());
        const values = Array.from(uniqueFieldsMap.values());

        keys.splice(insertIndex, 0, header);
        values.splice(insertIndex, 0, templateDisplayHeaders[index]);

        // Rebuild the map based on the updated keys and values.
        uniqueFieldsMap.clear();
        keys.forEach((key, idx) => {
          uniqueFieldsMap.set(key, values[idx]);
        });
      }
    });
  });

  return {
    rowHeaders: Array.from(uniqueFieldsMap.keys()),
    displayHeaders: Array.from(uniqueFieldsMap.values())
  };
}

export function updateProductTemplates(
  params: UpdateProductTemplatesParams
): ProductSegmentTemplate {
  const {
    arps,
    typeWellTemplates,
    arpsWasm,
    kind,
    setTemplateError,
    cumulativeData,
    declineType,
    typeWellSettings,
    updateEUR
  } = params;

  const updatedProductTemplates = getSegmentTypeUsingArps({
    arps,
    typeWellTemplates
  });

  // Call getData for each arps product and update the template
  for (const product in updatedProductTemplates) {
    const filteredArps = {
      ...arps,
      arps: arps.arps.filter((segment) => segment.product === product)
    };

    updatedProductTemplates[product] = getData({
      arps: filteredArps,
      arpsLib: arpsWasm,
      segmentTemplateName: updatedProductTemplates[product].name,
      kind,
      setTemplateError,
      typeWellTemplates,
      cumulativeData,
      declineType,
      typeWellSettings,
      updateEUR
    });
    updatedProductTemplates[product] = updateHeaders(
      updatedProductTemplates[product],
      declineType
    );
  }

  if (arps?.constants == undefined) {
    return updatedProductTemplates;
  }

  // Forecast constants
  for (const constant of arps.constants) {
    const product = constant.product;
    updatedProductTemplates[product] = {
      ...updatedProductTemplates[product],
      rowHeaders: ["Qi"],
      displayHeaders: ["Q.i"],
      productData: [{ Qi: constant.value }],
      productCols: [product]
    };
  }

  return updatedProductTemplates;
}
